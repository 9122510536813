.header-li {
  width: 11.25rem;
  text-align: center;
  cursor: pointer;
}

.header-section {
  margin: 0 auto;
  display: flex;
  justify-content: flex-end;

  max-width: 1000px;
  margin: 0 auto;
}

.mobile--nav {
  display: none;
}

.header-section-nav-li {
  padding: 0.5rem 0;
  margin: 0.125rem 0;
}

.header-section-nav-li:hover:after {
  font-weight: 600;
  width: 60%;
}

.header-section-nav-li:after {
  transition: all ease-in-out 0.2s;
  background: none repeat scroll 0 0 #008cd6;
  content: "";
  display: block;
  height: 2px;
  width: 0;
}

.header-section-nav {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.header--div {
  max-width: 1000px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header--nav--ul {
  display: flex;
  width: 100%;
  justify-content: space-between;
  list-style: none;
  font-weight: 700;
  color: black;
  position: relative;
  padding: 1.7rem 0;
}

.visible {
  display: flex;
}

.unVisible {
  display: none;
}

.show-menu {
  position: absolute;
  right: 0px;
  width: 100vw;
  background: #008cd6;
  border-top: #e6e6e6 solid 1px;
}

.show-menu-li {
  width: 100%;
}

.hide-menu {
  width: auto;
  position: absolute;
  display: none;
}

@media screen and (max-width: 1100px) {
  .header-section,
  .header--div {
    max-width: 800px;
  }
}
@media screen and (max-width: 900px) {
  .header-section {
    margin: 0 20px;
  }
  .header--div--a {
    margin-left: 20px;
  }
}
@media screen and (max-width: 780px) {
  .header--nav {
    display: none;
  }
  .mobile--nav {
    display: block;
  }
}
