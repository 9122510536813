@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=pretendard:ital,wght@0,400;0,700;1,400&display=swap");

.ck {
  max-width: 1000px;
  @media screen and (max-width: 900px) {
    margin: 0 20px 12.5rem;
  }
  @media screen and (max-width: 1100px) {
    max-width: 800px;
  }
}

.ck.ck-editor {
  width: 100%;
}

.ck.ck-content {
  font-family: "pretendard", serif;
  font-size: 16px;
  line-height: 1.6;
  padding: 2em;
  background-color: #272822;
  min-height: 50vh;
  max-width: 1000px;
  overflow: hidden;
  margin: 0 auto;
  @media screen and (max-width: 900px) {
    margin: 0 20px 12.5rem;
  }
  @media screen and (max-width: 1100px) {
    max-width: 800px;
  }
}

.ck-content figure {
  max-width: 100%;
}

.ck-content .ck-horizontal-line {
  margin-bottom: 1em;
}

.ck.ck-content hr {
  width: 100px;
  border-top: 1px solid #aaa;
  height: 1px;
  margin: 1em auto;
}

.ck.ck-content h2 {
  font-size: 50px;
  font-weight: bold;
  margin: 0 !important;
  padding: 0;
  border: 0;
}

.ck.ck-content h3 {
  font-size: 30px;
  font-weight: bold;
  padding: 0;
}

.ck.ck-content i {
  font-style: italic;
}

.ck.ck-content ul {
  list-style-type: disc;
}

.ck.ck-content strong {
  font-weight: 900;
}

.ck.ck-content a {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

.ck.ck-content ol {
  list-style-type: decimal;
}

.ck.ck-content blockquote.side-quote {
  font-family: "Bebas Neue";
  font-style: normal;
  float: right;
  width: 35%;
  position: relative;
  border: 0;
  overflow: visible;
  z-index: 1;
  margin-left: 1em;
}

.ck.ck-content blockquote.side-quote::before {
  content: "“";
  position: absolute;
  top: -37px;
  left: -10px;
  display: block;
  font-size: 200px;
  color: #e7e7e7;
  z-index: -1;
  line-height: 1;
}

.ck.ck-content blockquote.side-quote p {
  font-size: 2em;
  line-height: 1;
}

.ck.ck-content blockquote.side-quote p:last-child:not(:first-child) {
  font-size: 1.3em;
  text-align: right;
  color: #555;
}

.ck.ck-content span.marker {
  background: yellow;
}

.ck.ck-content span.spoiler {
  background: #000;
  color: #000;
}

.ck.ck-content span.spoiler:hover {
  background: #000;
  color: #fff;
}

.ck.ck-content pre.fancy-code {
  border: 0;
  margin-left: 2em;
  margin-right: 2em;
  border-radius: 10px;
}

.ck.ck-content pre.fancy-code::before {
  content: "";
  display: block;
  height: 13px;
  background: url(data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1NCAxMyI+CiAgPGNpcmNsZSBjeD0iNi41IiBjeT0iNi41IiByPSI2LjUiIGZpbGw9IiNGMzZCNUMiLz4KICA8Y2lyY2xlIGN4PSIyNi41IiBjeT0iNi41IiByPSI2LjUiIGZpbGw9IiNGOUJFNEQiLz4KICA8Y2lyY2xlIGN4PSI0Ny41IiBjeT0iNi41IiByPSI2LjUiIGZpbGw9IiM1NkM0NTMiLz4KPC9zdmc+Cg==);
  margin-bottom: 8px;
  background-repeat: no-repeat;
}

.ck.ck-content pre.fancy-code-dark {
  background: #272822;
  color: #fff;
  box-shadow: 5px 5px 0 #0000001f;
}

.ck.ck-content pre.fancy-code-bright {
  background: #dddfe0;
  color: #000;
  box-shadow: 5px 5px 0 #b3b3b3;
}
