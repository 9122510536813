footer {
  width: 100%;
  background-color: #d7d7d7;
}

footer > div {
  max-width: 950px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 0;
}

@media screen and (max-width: 1100px) {
  footer > div {
    max-width: 800px;
  }
}
@media screen and (max-width: 900px) {
  footer > div {
    margin: 0 20px;
  }
}
@media screen and (max-width: 620px) {
  footer > div {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }
}
